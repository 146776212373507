#detail-page-v2 {

  .box-buttons-s1 {
    padding-top: 30px;

    .btn-s1 {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      font-size: 1.20rem;
      font-weight: 500;
    }

    @media screen and (max-width: 1199px) {
      .btn-s1 {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.1rem;
      }
    }
  }

  .main-section {
    padding-top: 35px;
    padding-bottom: 40px;
    color: #ffffff;
    background-color: #151515;

    a {
      color: #ffffff;
    }

    .container {
      >.row {
        min-height: 81vh;
        align-items: center;
      }
    }

    .col-info {
      .name {
        margin-bottom: 14px;
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 1.1;
      }

      .box-descr {
        display: block;
        line-height: 1.6;
        font-size: 0.95rem;

        p {
          margin-bottom: 8px;
        }
      }

      .box-countdown {
        padding-top: 24px;

        .box-title {
          display: block;
          margin-bottom: 16px;
          text-align: center;

          h6 {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: 2px;
          }
        }

        >span {
          display: flex;
          flex-wrap: wrap;
          flex: 0 0 100%;
          max-width: 100%;

          .box-digit {
            display: inline-flex;
            flex: 0 0 110px;
            flex-wrap: wrap;

            .b {
              display: flex;
              flex: 0 0 100%;
              max-width: 100%;
              justify-content: center;
              font-family: "Montserrat", sans-serif;
            }

            .number {
              margin-bottom: 8px;
              font-size: 3.2rem;
              font-weight: 400;
              line-height: 1;
            }

            .text {
              font-size: 1.0rem;
              font-weight: 400;
            }
          }
        }
      }
    }

    .col-video {
      .video {
        position: relative;
        display: block;
        width: 100%;

        iframe, .y-video {
          width: 100%;
          height: 303px;
          box-shadow: 0 0 16px rgba(255, 255, 255, 0.4);
          border: 1px solid rgba(255, 255, 255, 0.2);
          @include transition(450ms);
        }
      }
    }

    @media screen and (min-width: 992px) {
      .col-info {
        padding-right: 40px;
      }

      .col-video {
        .video {
          iframe, .y-video {
            transform: perspective(900px) rotateX(0deg) rotateY(-22deg) rotateZ(0deg);

            &:hover {
              transform: perspective(900px) rotateX(0deg) rotateY(5deg) rotateZ(0deg) scale(1.06);
            }
          }
        }
      }

      @media screen and (max-width: 1199px) {
        .col-info {
          .name {
            font-size: 2.00rem;
          }

          .box-countdown {
            .box-title {
              h6 {
                font-size: 1.4rem;
              }
            }

            >span {
              .box-digit {
                flex: 0 0 25%;
                max-width: 25%;

                .number {
                  font-size: 2.7rem;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      .col-info {
        text-align: center;

        .box-countdown {
          >span {
            justify-content: center;
          }
        }
      }

      .col-video {
        margin-top: 35px;

        .video {
          .y-video {
            height: auto;
          }
        }
      }
    }
    @media screen and (max-width: 575px) {
      .col-info {
        .name {
          font-size: 2.0rem;
        }

        .box-countdown {
          .box-title {
            h6 {
              font-size: 1.10rem;
              font-weight: 700;
            }
          }

          >span {
            .box-digit {
              flex: 0 0 25%;
              max-width: 25%;

              .number {
                font-size: 2.6rem;
              }

              .text {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  .gallery-section {
    position: relative;
    padding: 32px 0 38px 0;
    background-color: #ebe9e9;

    .title-s1 {
      font-size: 2.4rem;
    }

    .swiper {
      .swiper-slide {
        .box-image {
          display: block;
          height: 280px;
          border: 3px solid $cprimary;
          background-color: $soft-gray;
          cursor: pointer;
          @include transition(250ms);

          &:hover {
            opacity: 0.85;
          }
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 11px;
          height: 11px;
          border: 1px solid #111111;
          box-shadow: 0 0 4px rgba(0,0,0,0.4);
          background-color: #ffffff;
        }
      }
    }
  }

  .info-section {
    padding-top: 40px;
    padding-bottom: 40px;

    .col-options {
      margin-bottom: 20px;

      .col-btn {
        margin-top: 5px;
        margin-bottom: 5px;

        .btn-s1 {
          width: 100%;
          font-weight: 500;
        }
      }
    }

    .col-info {
      min-height: 600px;
      max-height: 69vh;
      overflow: auto;
      overflow-x: hidden;

      .name {
        margin-bottom: 14px;
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 1.1;
      }

      .descr {
        display: block;

        ul, ol {
          padding-left: 16px;
        }
      }

      .price {
        color: $csecondary;
        font-family: "Montserrat", sans-serif;
        font-size: 2.3rem;
        font-weight: 700;
        letter-spacing: 1px;
      }

      .box-buttons-s1 {
        padding-top: 15px;
      }
    }

    .col-gallery {
      img {
        width: 100%;
        border-radius: 8px;
      }

      .price {
        color: $csecondary;
        font-family: "Montserrat", sans-serif;
        font-size: 2.2rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-align: center;
      }
    }

    @media screen and (min-width: 768px) {
      .col-options {
        >.row {
          margin-left: -7px;
          margin-right: -7px;
        }

        .col-btn {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .col-info {
        min-height: auto;
        max-height: inherit;
      }

      .col-gallery {
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 575px) {
      .col-info {
        text-align: center;

        .name {
          font-size: 2.0rem;
        }

        .descr {
          font-size: 0.95rem;
        }

        .price {
          font-size: 2.15rem;
        }
      }
    }
  }

  .testimonials-section {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #ebe9e9;

    .col-title {
      text-align: center;
      margin-bottom: 46px;
    }

    .col-testimonial {
      margin-bottom: 60px;

      .box-testimonial {
        position: relative;
        display: block;
        padding: 18px 24px 22px 24px;
        color: #ffffff;
        border-radius: 0;
        background-color: $csecondary;

        .b-photo {
          position: relative;
          display: block;
          text-align: center;

          .photo {
            position: relative;
            display: inline-block;
            margin-top: -50px;
            width: 100px;
            height: 100px;
            border: 2px solid $cprimary;
            border-radius: 50%;
            background-color: #ffffff;
          }
        }

        .b-text {
          height: 121px;
          margin-top: 5px;
          font-size: 0.85rem;
          text-align: center;
          overflow: hidden;
        }
      }
    }

    @media screen and (min-width: 992px) {
      .col-testimonial {
        &:nth-last-of-type(-n+2) {
          margin-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .col-testimonial {
        &:last-child {
          margin-bottom: 0;
        }

        .box-testimonial {
          .b-text {
            height: auto;
          }
        }
      }
    }
  }

}
