#text-page{
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 77vh;
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: justify;
  background-color: #f6f6f6;

  .page-title{
    color: #272626;
    font-size: 1.65rem;
    font-weight: 600;
    text-align: center;
  }

  h2,h3,h4,h5,h6{
    color: #000000;
    font-weight: 600;
  }

  h4{
    font-size: 1.35rem;
  }
  h5{
    font-size: 1.1rem;
  }
  h6{
    font-size: 0.95rem;
  }

  ul, ol{
    padding-left: 16px;
    li{}
  }

  @media screen and (max-width: 1199px){
    padding-top: 65px;
  }
  @media screen and (max-width: 575px){
    .page-title{
      font-size: 1.45rem;
    }
  }
}
