#article-results-page {

	.main-section {
		padding-top: 35px;
		padding-bottom: 35px;

		.col-title {
			margin-bottom: 22px;
		}

		.col-results {
			.col-article-sample-1 {
				margin-bottom: 25px;

				.box-article {
					box-shadow: 0 0 3px rgba(0,0,0,0.3);
				}
			}
		}

		@media screen and (max-width: 767px) {
			.col-results {
				.col-article-sample-1 {
					margin-bottom: 15px;
				}
			}
		}
	}

}
