#detail-page-s2 {

  .main-section {
    position: relative;
    width: 100%;
    overflow: hidden;

    .container {
      >.row {
        min-height: 86vh;
        align-items: center;
      }
    }

    .col-info {
      padding-top: 25px;
      padding-bottom: 25px;

      .box-descr {
        line-height: 1.4;
        color: #474645;
        font-size: 0.95rem;
        text-align: justify;

        p {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .col-image {
      position: relative;
      padding: 0;
      align-self: stretch !important;

      .image-container {
        position: relative;
        float: right;
        width: 43.2vw;
        height: 100%;
        box-shadow: -2px 0 3px rgba(0,0,0,0.1);
        background-color: $soft-gray;

        .swiper {
          position: relative;
          width: 100%;
          height: 100%;

          .swiper-slide {
            padding: 0 1px;
            background-color: #ffffff;

            .swiper-image {
              position: relative;
              width: 100%;
              height: 100%;
              background-color: #ffffff;
            }
          }

          .swiper-pagination {
            .swiper-pagination-bullet {
              opacity: 0.4;
              background-color: #ffffff;
              width: 10px;
              height: 10px;
            }

            .swiper-pagination-bullet-active {
              opacity: 1 !important;
            }
          }
        }
      }

      .image {
        position: relative;
        float: right;
        width: 43.2vw;
        height: 100%;
        box-shadow: -2px 0 3px rgba(0,0,0,0.1);
        background-color: $soft-gray;

        &:before {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background: rgb(0,0,0);
          background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
          z-index: 2;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      .col-image {
        .image-container {
          width: 50.3vw;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .col-image {
        position: inherit;
        height: 280px;
        order: 0;

        .image-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 280px;

          &:before {
            height: 90%;
          }
        }
      }

      .col-info {
        padding-top: 12px;
        order: 1;
      }
    }
  }

  .related-section {
    position: relative;
    padding: 40px 0 38px 0;
    background-color: #efefef;

    .box-title {
      margin-bottom: 22px;
      text-align: center;

      h2 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.15;
        letter-spacing: 2px;
      }
    }

    .swiper {
			.swiper-wrapper {
				.swiper-slide {
					padding: 4px 4px 32px 4px;
				}
			}

			.swiper-pagination {
				bottom: 0;

				.swiper-pagination-bullet {
					margin: 0 6px;
					width: 22px;
					height: 5px;
					border-radius: 0;
					background-color: #82827e;
					opacity: 1;
				}

				.swiper-pagination-bullet-active {
					background-color: #1d1d1b;
				}
			}
		}
  }

}
