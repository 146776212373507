#footer{
  position: relative;

  .newsletter-section{
    position: relative;
    padding: 30px 0 30px 0;
    color: #313131;
    background-color: #fbf8f8;
    border-top: 1px solid $csecondary;

    .col-logo{
      a{
        display: inline-block;
      }

      img{
        max-height: 80px;
        max-width: 100%;
      }
    }

    .col-newsletter{
      .col-text{
        flex: 0 0 360px;
        max-width: 360px;

        h5{
          font-weight: 700;
          @media screen and (min-width: 992px) {
            font-size: 1.55rem;
          }
        }
      }

      .col-form{
        form{
          position: relative;
          border: 1px solid $csecondary;

          .form-group{
            margin-bottom: 0;

            .form-control{
              padding-left: 18px;
              padding-right: 18px;
              height: 40px;
              font-size: 0.95rem;
              font-weight: 500;
              border: 0 !important;
              border-radius: 0;
            }
          }

          .btn-snewsletter{
            max-width: 164px;
            height: 40px;
            font-size: 0.95rem;
            font-weight: 600;
            border: 0 !important;
            border-radius: 0;
            background-color: $csecondary;
          }
        }
      }
    }

    @media screen and (max-width: 991px){
      padding: 26px 0 14px 0;

      .col-logo{
        text-align: center;
        margin-bottom: 14px;

        img{
          max-width: 100%;
          max-height: 50px;
        }
      }

      .col-newsletter{
        .col-text{
          margin-bottom: 8px;
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }

  .footer-section{
    position: relative;
    padding: 8px 0 20px 0;
    color: #454444;
    background-color: #f2efef;
    z-index: 85;

    a{
      display: inline-block;
      color: #454444;
    }

    .col-footer{
      padding-top: 14px;
      padding-bottom: 14px;

      h5{
        margin-bottom: 10px;
        color: #282727;
        font-size: 1.05rem;
        font-weight: 500;
        letter-spacing: 1px;
      }

      .link-footer{
        font-size: 1.05rem;
        font-weight: 400;

        &.txt-sm {
          font-size: 0.91rem;
        }
      }

      .copy-text{
        font-size: 0.90rem;
        font-weight: 400;
      }
    }

    .col-copyright{
      text-align: right;

      .p-sm{
        font-size: 0.86rem;
        line-height: 1.35;

        &.p-icon{
          position: relative;
          padding-right: 32px;

          &:after{
            position: absolute;
            top: 2px;
            right: 0;
            color: #585656;
            font-family: "Font Awesome 5 Pro";
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1;
          }

          &.i-letter{
            &:after{
              content: "\f0e0";
            }
          }
          &.i-phone{
            &:after{
              content: "\f58f";
            }
          }
          &.i-map{
            &:after{
              content: "\f3c5";
            }
          }
        }
      }

      .btn-network{
        position: relative;
        display: inline-block;
        margin: 0 2px;

        span{
          @include flex-center-xy();
          width: 38px;
          flex: 0 0 38px;
          max-width: 38px;
          height: 38px;
          color: #ffffff;
          font-size: 1.2rem;
          border-radius: 50%;
          background: $csecondary;
        }

        &:hover{
          opacity: 0.8;
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-footer{
        padding-top: 6px;
        padding-bottom: 6px;
        text-align: center;

        h5{
          margin-bottom: 2px;
          font-size: 1.05rem;
        }
      }

      .col-copyright{
        .p-sm{
          &.p-icon{
            padding-right: 0;

            &:after{
              display: none;
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .col-footer{
        font-size: 1.0rem;
      }
    }
  }
}
