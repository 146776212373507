.col-article-sample-1 {
  .box-article {
    position: relative;
    display: block;
    width: 100%;
    color: #000000;
    background-color: #ffffff;

    .box-image {
      display: block;
      width: 100%;
      height: 184px;
      background-color: $soft-gray;
    }

    .box-text {
      display: block;
      padding: 22px 25px 28px 25px;

      .category {
        margin-bottom: 8px;
        color: #1c77c4;
        font-weight: 700;
      }

      .title {
        position: relative;
        height: 87px;
        color: #000000;
        font-size: 1.5rem;
        font-weight: 700;
        overflow: hidden;
      }

      .date {
        margin-top: 10px;
        color: #5e5e5e;
        font-weight: 700;
      }
    }
  }

  a.box-article {
    @include transition(250ms);
    text-decoration: none !important;

    &:hover {
      opacity: 0.85;
    }
  }
}
