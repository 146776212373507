body,html{
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	color: #000000;
}

p,h1,h2,h3,h4,h5,h6{
	margin-bottom: 0px;
}

#app {
	min-width: 340px;
}

// Container
.oversized-container{
	@media screen and (min-width: 992px){
		max-width: 98%;
	}
	@media screen and (min-width: 1630px){
		max-width: 1590px;
	}
}

// Backgrounds
.placed-backg{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Remove outline
select:focus, select:active, select:hover, button:focus, button:active, button:hover,
input:focus, input:active, input:hover, textarea:focus, textarea:active, textarea:hover{
  box-shadow: none !important;
  outline: none !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}

// WhatsApp link
.ic-whatsapp{
	position: fixed;
	right: 18px;
	z-index: 70;

	.ic-link {
		@extend .flex-center-xy;
		position: relative;
		padding-bottom: 2px;
		width: 60px;
		height: 60px;
		color: #ffffff !important;
		text-align: center;
		font-size: 32px;
		background-color: $soft-gray;
		border-radius: 50%;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			min-width: 62px;
			top: 13px;
			right: 54px;
			padding: 5px 15px 4px 12px;
			font-size: 1.00rem;
			font-weight: 700;
			border-radius: 6px 0 0 6px;
			background-color: $csecondary;
			z-index: -1;
		}

		&:hover{
			text-decoration: none;
		}
	}

	&.gdl {
		bottom: 92px;

		.ic-link {
			background-color: #0db94d;

			&:before {
				content: "GDL";
			}
		}
	}
	&.mty {
		bottom: 20px;

		.ic-link {
			background-color: #25d366;

			&:before {
				content: "MTY";
			}
		}
	}
}
