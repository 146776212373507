#course-results-page {

	.main-section {
		padding-top: 35px;
		padding-bottom: 35px;

		.col-title {
			margin-bottom: 25px;

			.title-s1 {
				line-height: 1.0;

				small {
					font-size: 70%;
					font-weight: 500;
					text-decoration: underline;
				}
			}
		}

		.col-results {
			.col-course-sample-1 {
				margin-bottom: 25px;

				.box-course {
					box-shadow: 0 0 3px rgba(0,0,0,0.6);
				}
			}
		}

		@media screen and (max-width: 767px) {
			.col-results {
				.col-course-sample-1 {
					margin-bottom: 15px;
				}
			}
		}
	}

}
