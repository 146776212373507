// Modal open
.modal-open{
  >#app{
    filter: blur(1px);
  }
}

// == Modal search (Header) ==
.modal-search-e{
  .modal-content{
    background-color: transparent;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      height: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.85rem 1rem;
        top: 10px;
        right: 16px;
        font-size: 28px;
        font-weight: 600;
      }
    }

    .modal-body{
      padding: 0;

      form{
        position: relative;
        width: 100%;

        .form-group{
          margin: 0 !important;

          .form-control{
            padding-left: 78px;
            padding-right: 38px;
            padding-bottom: 0.6rem;
          }
        }

        .btn-search{
          position: absolute;
          top: 0;
          left: 0;
          width: 56px;
          height: 100%;
          color: #ffffff;
          font-size: 18px;
          border: 0 !important;
          background-color: $csecondary;
          z-index: 10;

          &:hover, &:focus, &:active{
            background-color: $csecondary2 !important;
          }
        }
      }
    }
  }
}
// == ==

// == Modal Suscríbete ==
.modal-subscribe {
  .modal-dialog {
    .modal-content {
      border: 0;
      border-radius: 0;
      background-color: #d5d5d5;

      .modal-header {
        padding: 1.5rem 1rem 0.3rem 1rem;
        border-bottom: 0;

        .modal-title {
          width: 100%;
          color: #313131;
          font-size: 2.6rem;
          font-weight: 700;
          letter-spacing: 2px;
          line-height: 1.0;
          text-align: center;
        }

        .close {
          position: relative;
          padding: 0rem 1rem;
          right: 4px;
          color: #000000;
          font-size: 2.9rem;
          font-weight: 400;
          line-height: 1;
          opacity: 1;
          z-index: 30;
        }
      }

      .modal-body {
        padding: 1rem 2.4rem 1.4rem 2.4rem;
        color: #313131;

        .box-opts {
          display: block;
          padding-top: 7px;

          .c-opts-group {
            .custom-radio {
              padding: 0;
              width: 100%;
              margin-right: 0;
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }

              .custom-control-label {
                width: 100%;

                &:before, &:after {
                  display: none;
                }

                .box {
                  position: relative;
                  display: block;
                  padding: 8px 15px;
                  color: $csecondary;
                  cursor: pointer;
                  font-size: 1.05rem;
                  font-weight: 500;
                  text-align: center;
                  border: 2px solid $csecondary;
                  border-radius: 3px;
                  background-color: transparent;
                }
              }

              .custom-control-input:checked ~ .custom-control-label {
                .box {
                  color: #ffffff;
                  background-color: $csecondary;
                }
              }
            }
          }
        }

        .box-prices {
          display: block;
          text-align: right;
          padding-top: 18px;

          .lab {
            letter-spacing: 2px;
          }

          .price {
            margin-top: 5px;
            font-weight: 700;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 1rem 1.4rem 1.4rem 1.4rem;
        }
      }
    }
  }
}
// == ==

// == Modal gallery ==
#modal-gallery-s1{
  .modal-content{
    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      z-index: 10;

      .close{
        position: absolute;
        padding: 5px 10px;
        top: 17px;
        right: 22px;
        color: #fff;
        font-size: 32px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
        opacity: 1;
      }
    }

    .modal-body{
      padding: 0;

      .img-modal{
        width: 100%;
        border: 3px solid #fff;
        border-radius: 2px;
      }
    }
  }
}
// == ==
