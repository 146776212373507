.btn-s1 {
  border-radius: 0;
  letter-spacing: 2px;

  &.bg-cprimary {
    color: #ffffff;
    border-color: $cprimary;
    background-color: $cprimary;

    &:hover, &:focus, &:active {
      border-color: $cprimary2;
      background-color: $cprimary2;
    }
  }

  &.bg-csecondary {
    color: #ffffff;
    border-color: $csecondary;
    background-color: $csecondary;

    &:hover, &:focus, &:active {
      border-color: $csecondary2;
      background-color: $csecondary2;
    }
  }

  &.bg-outline-cprimary {
    color: $cprimary;
    border: 1px solid $cprimary;
    background-color: transparent !important;

    &:hover, &:focus, &:active {
      color: $cprimary2;
      border-color: $cprimary2;
    }
  }
}
