#header{
	position: relative;
	z-index: 60;

	// == Payments ==
	.header-content{
		// position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 0px 4px rgba(0,0,0,0.4);
	}
	// == ==

	// == Header menu ==
	.header-menu {
		.navbar {
			background-color: #ffffff !important;

			.navbar-brand {
				img {
					max-height: 64px;
				}
			}

			.box-btns-out {
				display: inline-flex;
				margin-left: auto;
				margin-right: 16px;
				color: $csecondary;

				.btn-extra {
					@include flex-center-xy();
					margin: 0 7px;
					width: 38px;
					height: 38px;
					color: #ffffff;
					cursor: pointer;
					font-size: 1.25rem;
					text-decoration: none !important;
					border: 1px solid $csecondary;
					border-radius: 50%;
					background-color: $csecondary;

					.fa-envelope {
						font-size: 1.30rem;
					}
				}
			}

			.navbar-toggler {
				padding: 0.35rem 0.95rem;
				color: #ffffff;
				font-size: 1.6rem;
				border-color: $csecondary;
				border-radius: 3px;
				background-color: $csecondary;
			}

			.navbar-nav {
				.nav-item {
					.nav-link {
						@include transition(150ms);
						letter-spacing: 1px;
					}
				}

				.simple-item {
					.nav-link {
						color: $csecondary;
						font-size: 1.10rem;

						&:hover {
							color: $csecondary2;
						}
					}
				}

				.btn-item {
					.nav-link {
						@include transition(250ms);
						padding: 0.5rem 1.65rem;
						color: #ffffff;
						font-size: 1.05rem;
						font-weight: 500;
						background-color: $csecondary;

						&:hover {
							background-color: $csecondary2;
						}
					}
				}

				.icon-item {
					.nav-link {
						padding: 3px 0 0 0;
						width: 40px;
						height: 40px;
						color: $csecondary;
						cursor: pointer;
						border: 1px solid $csecondary;
						border-radius: 50%;
						@include flex-center-xy();

						.icon {
							font-size: 1.20rem;

							&.fa-envelope {
								padding-top: 3px;
								padding-left: 1px;
								font-size: 1.3rem;
							}
						}

						&:hover {
							color: #ffffff;
							background-color: $csecondary;
						}
					}
				}

				.icon-item {
					&.dropdown {
						.nav-link {
							&:after {
								display: none;
							}
						}

						.dropdown-menu {
							padding: 0.35rem 0;
							border-radius: 0;
							border: 0;
							background-color: $cprimary2;

							li {
								.dropdown-item {
									padding: 0.2rem 1.2rem;
									color: #ffffff;
									font-size: 0.93rem;

									&:hover {
										background-color: #2d1a59;
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				.btn-extra {
					display: none !important;
				}

				.navbar-nav {
					.simple-item {
						margin-left: 1.4rem;
						margin-right: 1.4rem;
					}

					.icon-item {
						margin-left: 0.4rem;
						margin-right: 0.4rem;
					}

					.btn-item {
						margin-left: 20px;
					}
				}
			}

			@media screen and (max-width: 1199px) {
				.navbar {
					.navbar-nav {
						.simple-item {
							margin-left: 0.5rem;
							margin-right: 0.6rem;
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar {
				.navbar-brand {
					padding-top: 0.15rem;
					padding-bottom: 0.15rem;

					img {
						max-height: 40px;
					}
				}

				#nav-collapse {
					.navbar-nav {
						.simple-item {
							margin-top: 1px;
							margin-bottom: 1px;

							.nav-link {
								padding: 0.3rem 0;
								font-size: 1.05rem;
							}
						}

						.icon-item {
							display: none;

							.nav-link {
								.icon {
									margin-right: 2px;
									width: 26px;
									font-size: 1.25rem;
									text-align: center;
								}
							}
						}

						.btn-item {
							margin-top: 8px;
							margin-bottom: 5px;
							text-align: center;
						}
					}
				}
			}
		}
		@media screen and (max-width: 380px) {
			.navbar {
				.box-btns-out {
					margin-right: 14px;

					.btn-extra {
						margin: 0 3px;
					}
				}
			}
		}
	}
	// == ==

	// == Header payments ==
	.header-payments{
		position: relative;
		padding: 7px 5px;
		width: 100%;
		color: #fff;
		line-height: 1.2;
		text-align: center;
		background-color: $csecondary;

		.txt{
			position: relative;
			display: inline-block;
			margin-bottom: 0;
			top: 1px;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.15;
		}

		.imgs{
			display: inline-block;
			margin: 0 15px;

			img{
				max-height: 30px;
				margin: 0 6px;
			}
		}

		.b-1, .b-2{
			display: inline-block;
		}

		@media screen and (max-width: 1199px){
			.b-1, .b-2{
				display: block;
			}

			.b-1{
				.imgs{
					display: block;
				}
			}

			.imgs{
				img{
					height: 17px;
				}
			}
		}
		@media screen and (max-width: 991px){
			display: none;

			.txt{
				font-size: 0.85rem;
			}
			.imgs{
				margin-top: 7px;
				margin-bottom: 7px;
			}
		}
		@media screen and (max-width: 575px){
			display: none;
		}
	}
	// == ==

}
