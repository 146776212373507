.col-course-sample-1 {
  .box-course {
    position: relative;
    display: block;
    width: 100%;

    .box-image {
      position: relative;
      display: block;
      height: 428px;
      background-color: $soft-gray;
    }

    .box-title {
      position: absolute;
      padding: 18px 15px 26px 15px;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      color: #ffffff;
      background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);
      z-index: 10;

      h3 {
        font-size: 2.05rem;
        letter-spacing: 1px;
      }
    }
  }

  a.box-course {
    @include transition(250ms);
    cursor: pointer;

    &:hover {
      opacity: 0.90;
    }
  }

  @media screen and (max-width: 1599px) {
    .box-course {
      .box-image {
        height: 380px;
      }

      .box-title {
        h3 {
          font-size: 1.90rem;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .box-course {
      .box-image {
        height: 330px;
      }

      .box-title {
        h3 {
          font-size: 1.70rem;
        }
      }
    }
  }
}
