#home-page{

  // == Banners ==
	.banner-section{
		position: relative;
		padding: 0;

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					background-color: $soft-gray;

					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 991px) {
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile{
				display: block;
			}
		}
	}
	// == ==

	// == About ==
	.about-section {
		position: relative;
		width: 100%;
		overflow: hidden;
		padding: 85px 0;
		margin-top: 45px;
		color: #ffffff;
		background-color: #000000;

		.container {
			>.row {
				align-items: center;
				justify-content: center;
			}
		}

		.col-title {
			position: relative;
			padding-top: 20px;
			padding-bottom: 20px;
			margin: 0 auto;

			&:before {
				content: " ";
				position: absolute;
				top: -22px;
				left: -115px;
				width: 85px;
				height: 97px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url('../images/pages/home/hexagon.svg');
			}

			&:after {
				content: " ";
				position: absolute;
				bottom: -54px;
				right: -75px;
				width: 60px;
				height: 69px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url('../images/pages/home/hexagon.svg');
			}

			h2 {
				font-size: 2.9rem;
				font-weight: 700;
			}
		}

		.col-text {
			position: relative;
			padding-top: 20px;
			padding-bottom: 20px;
			margin: 0 auto;

			&:after {
				content: " ";
				position: absolute;
				top: -45px;
				right: -140px;
				width: 112px;
				height: 128px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url('../images/pages/home/hexagon.svg');
			}

			p {
				font-size: 1.8rem;
				font-weight: 600;
				line-height: 1.3;
			}
		}

		@media screen and (max-width: 991px) {
			.col-title {
				&:after {
					display: none;
				}

				h2 {
					text-align: center;
				}
			}

			.col-text {
				text-align: center;
			}
		}
		@media screen and (max-width: 575px) {
			padding: 32px 0;
			margin-top: 20px;

			.col-title {
				padding-bottom: 0;

				h2 {
					font-size: 2.1rem;
				}
			}

			.col-text {
				p {
					font-size: 1.3rem;
				}
			}
		}
	}
	// ==  ==

	// == Courses ==
	.courses-section {
		padding-top: 40px;
		padding-bottom: 42px;

		.col-title {
			margin-bottom: 22px;
		}

		.col-courses {
			.swiper {
				.swiper-button-prev, .swiper-button-next{
					color: #ffffff;

					&:after {
						font-size: 2.3rem;
						font-weight: 700;
					}
				}
			}
		}

		@media screen and (max-width: 1599px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@media screen and (max-width: 767px) {
			padding-top: 18px;
			padding-bottom: 20px;

			.col-title {
				margin-bottom: 12px;
			}
		}
	}
	// == ==

	// == Blog ==
	.blog-section {
		position: relative;
		padding: 55px 0;
		background-color: #000000;

		.col-blog {
			.swiper {
				.swiper-pagination {
					bottom: 7px;

					.swiper-pagination-bullet {
						opacity: 1;
						box-shadow: 0 0 2px rgba(255,255,255,0.8);
						background-color: $cprimary;
					}

					.swiper-pagination-bullet-active {
						background-color: $csecondary;
					}
				}
			}
		}		

		@media screen and (max-width: 575px) {
			padding: 20px 0;
		}
	}
	// == ==

}
